import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import './Dashboard.css';
import appConfig from '../../utils/appConfig';
import { Button, Typography } from '@mui/material';

interface Account {
  id: string;
  Account_Name: string;
  field2: string;
  logo_link: string | null;
  main_org: string;
  name_to_web: string;
  if_pay_hok_bank: boolean;
}

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [accounts, setAccounts] = useState<Account[]>([]);

  const { authorityName } = useParams<{ authorityName: string }>();
  const authority = appConfig.authorities.find(auth => auth.en === authorityName);

  const encryptionKey = 'hagay1234'; // מפתח הצפנה מאובטח

  // פונקציה לאימות תוויים
  const isValidAuthority = (authority: string | undefined): boolean => {
    const regex = /^[a-zA-Z]+$/; // רק אותיות
    return regex.test(authority || '');
  };

  // פונקציה להצפנת authority
  const encryptAuthority = (authority: string): string => {
    return CryptoJS.AES.encrypt(authority, encryptionKey).toString();
  };

  useEffect(() => {
    // Fetch all accounts data from the server
    const fetchAccounts = async () => {
      if (!authority || !isValidAuthority(authority.en)) {
        console.error('Invalid authority name. Only letters are allowed.');
        return;
      }

      const encryptedAuthority = encryptAuthority(authority.en);

      try {
        const response = await axios.get(`${appConfig.apiUrls.baseUrl}/zohoAccounts/getAllAccounts/${encodeURIComponent(encryptedAuthority)}`);

        // Function to clean the string by removing special characters like "-"
        const cleanString = (str: string) => {
          return str.replace(/-/g, '');
        };

        // Clean name_to_web for each account
        const cleanedAccounts = response.data.map((account: Account) => ({
          ...account,
          name_to_web: cleanString(account.name_to_web)
        }));

        setAccounts(cleanedAccounts);
      } catch (error) {
        console.error('Error fetching accounts data:', error);
      }
    };

    fetchAccounts();
  }, [authority?.en]);

  const handleNavigate = (account: Account) => {
    navigate(`/${authority?.en}/afternoons/${account.id}`, { state: { accounts, account } });
  };
  const handleNavigateToCheckId = () => {
    navigate(`/${authority?.en}/checkId`);  // שימוש ב-hook של React Router או כל דרך ניווט אחרת שיש לך במערכת
  };

  // תנאי חדש - אם authority?.en === 'eliad' מציג הודעה
  if (authority?.en === 'eliad') {
    return (
      <div className="error-message">
        <h1>דף זה אינו קיים יותר</h1>
        <h1>לפניות בנוגע לצהרונים של עמותת אליעד יש לפנות למספר 08-3750787</h1>
      </div>
    );
  }

  return (
    <div className="dashboard-container">
      {!authorityName && (
        <div className="error-message">
        <h1> url יש לרשום את שם הרשות באנגלית בסוף ה</h1>
        </div>
      )}
      {authorityName && (
        <>
          <Typography align="center" variant="h1">
            ברוכים הבאים למשפחת צהרוני {authority?.he}
          </Typography>
          <Typography align="center" variant="h5">
            {accounts.length === 1 ? 'לחץ כאן להתחלת תהליך הרישום' : 'אנא בחרו מסגרת בה תרצו להירשם'}
          </Typography>
          <div className="buttons-container">
            {accounts.map((account: Account) => (
              <div key={account.id} className="account-container">
                <button
                  className={`custom-button ${account.logo_link ? '' : 'text-only'}`}
                  onClick={() => handleNavigate(account)}
                >
                  {account.logo_link ? (
                    <img src={account.logo_link} alt={account.name_to_web} className="button-logo" />
                  ) : (
                    account.name_to_web
                  )}
                </button>
                {account.logo_link !== null && (
                  <div className="account-name">{account.name_to_web}</div>
                )}
              </div>
            ))}
          </div>
          {/* כפתור חדש לבדיקת תעודת זהות */}
          <div style={{ textAlign: 'center', marginTop: '20px', padding: '20px 0', backgroundColor: '#f9f9f9', flexShrink: '0' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNavigateToCheckId}
              sx={{ fontSize: '1rem', padding: '10px 20px' }}
            >
              לתשלום הפרשים וחובות
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
export default Dashboard;
