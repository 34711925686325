import React from 'react';
import ReactDOM from 'react-dom/client'; // עדכן את ה-import
import App from './App';
import './index.css'; // ודא שהקובץ מיובא
import { CacheProvider, ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [rtlPlugin],
});

const theme = createTheme({
  direction: 'rtl',
  typography: {
    fontSize: 18,
    h1: {
      fontSize: 40,
      fontWeight: 700,
      lineHeight: 1
    },
    h2: {
      fontSize: 38,
      fontWeight: 700,
      lineHeight: 1
    },
    h3: {
      fontSize: 36,
      fontWeight: 700,
      lineHeight: 1,
    },
    body1:{
      margin: 0,
      padding: 0
    },
    body2:{
      margin: 0,
      padding: 0,
      fontSize: 12,
    },
    fontFamily: [
      'ploni',
      'Open Sans',
      'sans-serif',
      'Arial',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {main: "#4caf50", contrastText: '#fff' },
    secondary: {main: "#00e676", contrastText: '#fff' } ,
    success:{ main: "#000", contrastText: '#fff' }
  },
});

// Function to update favicon
const updateFavicon = () => {
  const faviconElement = document.getElementById("dynamic-favicon");
  
  // Extract the last part of the URL
  const urlParts = window.location.pathname.split('/');
  const lastPart = urlParts[urlParts.length - 1];

  // Create the new favicon URL based on the last part
  const newFaviconUrl = `${process.env.PUBLIC_URL}/${lastPart}Favicon.png`;

  // Update the href attribute of the favicon link element
  if (faviconElement) {
    faviconElement.setAttribute('href', newFaviconUrl);
  }
};

// Call updateFavicon when the DOM is fully loaded
document.addEventListener("DOMContentLoaded", updateFavicon);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement); // יצירת root חדש
root.render(
  <CacheProvider value={cacheRtl}>
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ThemeProvider>
  </CacheProvider>
);
