import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import banksData from '../../data/banks.json'; // Update the path according to the location of the file
import axios from 'axios';
import appConfig from '../../utils/appConfig';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import terms_approval from '../../utils/termsAproval';

interface Bank {
  id: number;
  name_he: string;
  name_en: string;
}

const HokBankPay: React.FC = () => {
  const location = useLocation();
  const account = location.state?.account;
  const [bank, setBank] = useState<string>('');
  const [branchNumber, setBranchNumber] = useState<string>('');
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [studentDetails, setStudentDetails] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);


  const banks: Bank[] = banksData;
  let newStudentId = '';

  const { authorityName } = useParams<{ authorityName: string }>();

  const authority = appConfig.authorities.find(auth => auth.en === authorityName);

  const responseData = location.state?.responseData;
  const confirm = location.state?.confirm;
  const cancelFirstPayment = location.state?.cancelFirstPayment;
  console.log(cancelFirstPayment)

  const handleInputChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;

    // Allow only numeric values
    if (name!=="accountOwnerName") {
    if (!/^\d*$/.test(value)) {
      setErrors(prevErrors => ({ ...prevErrors, [name]: 'ניתן להקיש ספרות בלבד' }));
      return;
    } else {
      setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    }
  }
  

    setCurrentFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBlur = (name: string) => () => {
    setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
  
    setCurrentFormData(prevData => ({
      ...prevData,
      file: file, // עדכון שדה הקובץ ב-`currentFormData`
    }));
  
    if (file) {
      const base64 = await fileToBase64(file);
      const sha256 = await fileToSha256(file);
  
      setCurrentFormData(prevData => ({
        ...prevData,
        fileData: {
          base64,
          sha256,
        },
      }));
      setErrors(prevErrors => ({ ...prevErrors, file: ' ' }));
    } else {
      setCurrentFormData(prevData => ({
        ...prevData,
        fileData: null,
      }));
    }
  };
  


  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const fileToSha256 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async () => {
        const buffer = reader.result as ArrayBuffer;
        const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
        resolve(hashHex);
      };
      reader.onerror = (error) => reject(error);
    });
  };
  

  
  // const fetchStudentDetails = async () => {
  //   let response='';

  //   try {
  //     const res = await axios.get(`${appConfig.apiUrls.baseUrl}/zohoStudents/studentById/${formData.studentId}`);
  //     console.log(res.data)
  //     response=res.data;
  //     setStudentDetails(res.data);
  //     console.log("Student:", response);
  //     console.log("formData:", formData);
  //     console.log("organization:", account);
  //     console.log("mosad:", afternoon);
  //   } catch (error) {
  //     console.error('Error fetching student details:', error);
  //   }

  //   if(!response){
  //     const studentData = {
  //       data: [{
  //     First_Name:formData.firstName,
  //     Last_Name: formData.lastName,
  //     field: formData.studentId,
  //     Email: formData.parents.email,
  //     // Modified_Time: getCurrentDateTimeString(),
  //   }
  // ]
  //     };
  //     console.log("Student Data to be sent:", JSON.stringify(studentData, null, 2));
  //     try {
  //       const res = await axios.post(`${appConfig.apiUrls.baseUrl}/zohoStudents/addStudent`, {
  //         method: 'POST',
  //         body: JSON.stringify(studentData),
  //       });
    
      
  //       console.log('Payment added successfully:', res);
  //       newStudentId= res.data.data[0].details.id;
  //       console.log(newStudentId);
  //     }catch (error) {
  //       console.error('Error fetching student details:', error);
  //     }
  //   }

  // let contactDetails;

  // try {
  //   contactDetails = await axios.get(`${appConfig.apiUrls.baseUrl}/zohoStudents/studentById/${formData.studentId}`);
  //   console.log(contactDetails.data);
  //   setStudentDetails(contactDetails.data);
  // } catch (error) {
  //   console.error("Error fetching student details:", error);
  // }

  //     const studentData = {
  //       data: [{
  //     // Name: `${formData.firstName} ${formData.lastName} - ${afternoon.Name} - ${afternoon.year}`,
  //     Name: afternoon.Name,
  //     id_child: formData.studentId,
  //     account: account.id,
  //     mosad:  afternoon.id,
  //     field2:afternoon.year,
  //     contactid: newStudentId===''?contactDetails?.data[0].id:newStudentId,
  //     status: "פעיל- יש חוב",
  //     Layout:"5154700000000381085",
  //     // Modified_Time: getCurrentDateTimeString(),
  //   }
  // ]
  //     };
  //     console.log("Student Data to be sent:", JSON.stringify(studentData, null, 2));
  //     try {
  //       const res = await axios.post(`${appConfig.apiUrls.baseUrl}/zohoStudents/addScheduling`, {
  //         method: 'POST',
  //         body: JSON.stringify(studentData),
  //       });
    
      
  //       console.log('Student added successfully:', res);
  //     }catch (error) {
  //       console.error('Error fetching student details:', error);
  //     }
  // };


  // const handleAddPayment = async () => {
  //   const paymentData = {
  //     data: [{
  //       Name: `תשלום ${myData?.contactid.name}`,
  //       Email: transaction.user_defined_3||"אין אימייל",
  //       contactid: myData?.contactid.id,
  //       paydate:  `${transaction.transaction_date}T${transaction.transaction_time}`,
  //       IDcardholder: transaction.credit_card_owner_id,
  //       //payment_plan: transaction.payment_plan,
  //       type_pay: `${masofMethod}`,
  //       pay_notes: transaction?.user_defined_7,
  //       field2: myData.id,
  //       field3: transaction?.tranmode.startsWith('A')? "תשלום":(transaction?.tranmode.startsWith('C')?"זיכוי":""),
  //       field: transaction.index,
  //       field1: myData.field2,
  //       expmon: transaction.expiration_month,
  //       child_terminal:transaction.child_terminal,
  //       expyear:transaction.expiration_year,
  //       number_of_payments:transaction.number_of_payments,
  //       token:transaction.credit_card_token,
  //       first_payment:transaction.first_payment_amount,
  //       other_payments:transaction.other_payment_amount,
  //       amount:(transaction.amount/100),
  //       field8: "תקין",
  //     }]
  //   };
  
    
  //   console.log('Sending new payment:', paymentData);
  
  //   try {
  //     const res = await appFetch('/api/zohoPayments/addPayment', {
  //       method: 'POST',
  //       body: JSON.stringify(paymentData),
  //     });
  
  //     if (!res.ok) {
  //       const errorData = await res.json();
  //       console.error('Error adding payment:', errorData.message);
  //       setErrorMessage(errorData.message);
  //       setOpen(true);
  //       setLoading(false);
  //       return;
  //     }
  
  //     const data = await res.json();
  //     console.log('Payment added successfully:', data);
  //   } catch (error) {
  //     console.error('Error in handleAddScheduling:', error.message);
  //     setErrorMessage(error.message);
  //     setOpen(true);
  //     setLoading(false);
  //   }
  // };

  const getCurrentDateTimeString = () => {
    const now = new Date();
    const offset = now.getTimezoneOffset() * -1; // Get offset in minutes
    const offsetHours = Math.floor(offset / 60);
    const offsetMinutes = offset % 60;
    const offsetSign = offset >= 0 ? '+' : '-';
    const formattedOffset = `${offsetSign}${String(offsetHours).padStart(2, '0')}:${String(offsetMinutes).padStart(2, '0')}`;

    return now.toISOString().replace('Z', formattedOffset);
  };

  const [currentFormData, setCurrentFormData] = useState({
    accountOwnerName: '',
    bank: {
      id: '',
      name_he: '',
    },
    branchNumber: '',
    accountNumber: '',
    file: null as File | null,
    fileData: null as { base64: string; sha256: string } | null,
  });
  

  const appendFormData = (data: any, formData: FormData, root: string = ''): void => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        const formKey = root ? `${root}[${key}]` : key;

        if (typeof value === 'object' && !(value instanceof File)) {
          appendFormData(value, formData, formKey);
        } else {
          formData.append(formKey, value);
        }
      }
    }
  };
  const [formData, setFormData] = useState({});
  const [afternoon, setAfternoon] = useState({});

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // קריאת המידע מה-localStorage ושמירתו ב-state
    const storedFormData = localStorage.getItem('formData');
    const storedAfternoon = localStorage.getItem('afternoon');

    if (storedFormData) {
      setFormData(JSON.parse(storedFormData));
    }
    if (storedAfternoon) {
      setAfternoon(JSON.parse(storedAfternoon));
    }
  }, [location.search]);

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Validate bank selection
    if (currentFormData.bank.id === '') {
      setErrors(prevErrors => ({ ...prevErrors, bank: 'יש לבחור בנק' }));
      return;
    }
    if (!currentFormData.file) {
      setErrors(prevErrors => ({ ...prevErrors, file: 'יש להעלות קובץ אסמכתא להו"ק' }));
     
      return;
    }

    const dataToStore = {
      accountOwnerName: currentFormData.accountOwnerName,
      bank: currentFormData.bank,
      branchNumber: currentFormData.branchNumber,
      accountNumber: currentFormData.accountNumber,
      file: currentFormData.file ? currentFormData.file.name : null, // או שמירת המידע בצורה אחרת
      fileData: currentFormData.fileData ? { base64: currentFormData.fileData.base64, sha256: currentFormData.fileData.sha256 } : null,
    };
    
    // שמירת האובייקט הרגיל ב-localStorage
    localStorage.setItem('paymentData', JSON.stringify(dataToStore));
    
    // ניווט לעמוד התודה עם הצלחה
    navigate(`/${authority?.en}/thankYouPageHokBankPay`, { state: { success: true } });
    

    //   if (response.ok) {
    //     console.log('Data sent successfully');
    //     navigate(`/${authority?.en}/thankYouPageHokBankPay`, { state: { success: true } });
    //   } else {
    //     console.error('Failed to send data');
    //     navigate(`/${authority?.en}/thankYouPageHokBankPay`, { state: { success: false } });
    //   }
    // } catch (error) {
    //   console.error('Error sending data:', error);
    //   navigate(`/${authority?.en}/thankYouPageHokBankPay`, { state: { success: false } });
    // }
  };

  return (
    <Box sx={{ maxWidth: 400, margin: '0 auto', padding: 2 }}>
      <Typography variant="h6" sx={{ marginBottom: 2, textAlign: 'center' }}>
        פרטי הוראת קבע
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 2, textAlign: 'center' }}>
      {authority?.en === "eliad" && (
         <div style={{ textAlign: 'right', direction: 'rtl' }} dangerouslySetInnerHTML={{ __html: terms_approval.eliad_hok_Text }} />)}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="שם בעלי החשבון"
          name="accountOwnerName"
          value={currentFormData.accountOwnerName}
          onChange={handleInputChange('accountOwnerName')}
          onBlur={() => handleBlur('accountOwnerName')}
          sx={{ marginBottom: 2 }}
          InputProps={{ style: { textAlign: 'right' } }}
          InputLabelProps={{ style: { textAlign: 'right', direction: 'rtl' } }}
          error={!!errors.accountOwnerName}
          helperText={errors.accountOwnerName}
          required
        />
        <FormControl fullWidth sx={{ marginBottom: 2 }} required error={!!errors.bank}>
          <InputLabel id="bank-label" sx={{ textAlign: 'right', direction: 'rtl' }}>בחר בנק</InputLabel>
          <Select
            labelId="bank-label"
            id="bank-select"
            value={currentFormData.bank.id} // השתמש ב-id לבחירת הבנק
            onChange={(e) => {
              const selectedBank = banks.find(bank => bank.id.toString() === e.target.value);
              if (selectedBank) {
                setCurrentFormData(prevData => ({
                  ...prevData,
                  bank: {
                    id: selectedBank.id.toString(), // המר את id למחרוזת
                    name_he: selectedBank.name_he,
                  },
                }));
              }
            }}
            label="בחר בנק"
            sx={{
              textAlign: 'right',
              direction: 'rtl',
              '& .MuiSelect-select': {
                textAlign: 'right',
                direction: 'rtl',
              },
              '& .MuiSelect-icon': {
                right: 0,
                left: 'auto',
              },
              '& .MuiMenuItem-root': {
                textAlign: 'right',
                direction: 'rtl',
              },
            }}
          >
            <MenuItem value="" disabled sx={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'right', direction: 'rtl' }}>
              שם הבנק
            </MenuItem>
            {banks.map((bank) => (
                <MenuItem 
                  key={bank.id} 
                  value={bank.id.toString()} 
                  sx={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'right', direction: 'rtl' }}
                >
                  {bank.name_he}
                </MenuItem>
              ))}
          </Select>
          {errors.bank && <Typography variant="caption" color="error">{errors.bank}</Typography>}
        </FormControl>

        <TextField
          fullWidth
          label="מספר סניף"
          name="branchNumber"
          value={currentFormData.branchNumber}
          onChange={handleInputChange('branchNumber')}
          onBlur={() => handleBlur('branchNumber')}
          sx={{ marginBottom: 2 }}
          InputProps={{ style: { textAlign: 'right' } }}
          InputLabelProps={{ style: { textAlign: 'right', direction: 'rtl' } }}
          error={!!errors.branchNumber}
          helperText={errors.branchNumber}
          required
        />

        <TextField
          fullWidth
          label="מספר חשבון"
          name="accountNumber"
          value={currentFormData.accountNumber}
          onChange={handleInputChange('accountNumber')}
          onBlur={() => handleBlur('accountNumber')}
          sx={{ marginBottom: 2 }}
          InputProps={{ style: { textAlign: 'right' } }}
          InputLabelProps={{ style: { textAlign: 'right', direction: 'rtl' } }}
          error={!!errors.accountNumber}
          helperText={errors.accountNumber}
          required
        />

        <Button
          variant="contained"
          component="label"
          fullWidth
          sx={{ marginBottom: 2 }}
        >
          העלאת אסמכתא להו"ק
          <AttachFileIcon />
          <input
            type="file"
            hidden
            onChange={handleFileChange}
        
          />
        </Button>
        {currentFormData.file && <Typography sx={{ textAlign: 'right', marginBottom: 2 }}>{currentFormData.file?.name}</Typography>}
        {errors.file && <Typography variant="caption" color="error">{errors.file}</Typography>}


        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: 2 }}
        >

          {isLoading ? (

            'טוען...'
            ) : (
        'שלח'
      )}
        </Button>
      </form>
    </Box>
  );
};

export default HokBankPay;
