import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import Dashboard from './components/pages/Dashboard';
import Afternoons from './components/pages/Afternoons';
import Form from './components/pages/Form';
import HokBankPay from './components/pages/HokBankPay';
import CreditcardHok from './components/pages/CreditcardHok';
import CreditcardPay from './components/pages/CreditcardPay';
import ThankYouPage from './components/pages/ThankYouPage';
import ThankYouPageHokBankPay from './components/pages/ThankYouPageHokBankPay';
import appConfig from './utils/appConfig';
import CashOrCheckPay from './components/pages/CashOrCheckPay';
import ThankYouPageCashOrCheck from './components/pages/ThankYouPageCashOrCheck';
import CheckId from './components/pages/CheckId';
import PaymentsPage from './components/pages/PaymentPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Dashboard />, // Redirect to a default route
  },
  {
    path: 'thankYouPageRedirect',
    element: <ThankYouPage />,
  },
  {
    path: '/:authorityName',
    element: <Layout />,
    children: [
      {
        path: '',
        element: <Dashboard />,
      },
      {
        path: 'checkId',
        element: <CheckId />,
      },
      {
        path: 'paymentsPage',
        element: <PaymentsPage />,
      },
      {
        path: 'afternoons/:accountId',
        element: <Afternoons />,
      },
      {
        path: 'form/:afternoonId',
        element: <Form />,
      },
      {
        path: 'hokBankPay',
        element: <HokBankPay />,
      },
      {
        path: 'creditcardHok',
        element: <CreditcardHok />,
      },
      {
        path: 'creditcardPay',
        element: <CreditcardPay />,
      },
      {
        path: 'cashOrCheckPay',
        element: <CashOrCheckPay />,
      },
      {
        path: 'thankYouPageHokBankPay',
        element: <ThankYouPageHokBankPay />,
      },
      {
        path: 'thankYouPageCashOrCheck',
        element: <ThankYouPageCashOrCheck />,
      },
    ],
  },
]);

export default router;
