import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import './Dashboard.css';
import appConfig from '../../utils/appConfig';
import { Typography, TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box } from '@mui/material';

const CheckId: React.FC = () => {
  const navigate = useNavigate();
  const [idNumber, setIdNumber] = useState<string>('');
  const [idExists, setIdExists] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showMessage, setShowMessage] = useState(false);

  const [showDialog, setShowDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState<string | JSX.Element>("");
  

  const [dialogActions, setDialogActions] = useState<React.ReactNode | null>(null);


  const { authorityName } = useParams<{ authorityName: string }>();
  const authority = appConfig.authorities.find(auth => auth.en === authorityName);


  const handleIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
  
    // בדיקת הכנסת ספרות בלבד
    if (/^\d*$/.test(value)) {
      setIdNumber(value);
  
      // בדיקת אורך תעודת הזהות
      if (value.length === 9) {
        // לוגיקת בדיקת תקינות תעודת זהות ישראלית
        const isValid = Array.from(value, Number).reduce((counter, digit, i) => {
          const step = digit * ((i % 2) + 1);
          return counter + (step > 9 ? step - 9 : step);
        }, 0) % 10 === 0;
  
        if (isValid) {
          setError(null);
        } else {
          setError('מספר תעודת זהות לא תקין');
        }
      } else if (value.length !== 9) {
        setError('מספר תעודת זהות צריך להיות באורך 9 ספרות');
      } else {
        setError(null);
      }
    }
  };
  

  const handleIdSubmit = async () => {
    try {
      // ביצוע הבקשה לשרת
      const response = await axios.get(`${appConfig.apiUrls.baseUrl}/zohoStudents/selectedDataById/${idNumber}/${authorityName}/${appConfig.year}`);
      const data = response.data[0];
      const accountId = response.data[0].accountId;
      
      const response1 = await axios.get(`${appConfig.apiUrls.baseUrl}/zohoMosads/getAccount/${accountId}`);
      const account =  response1.data[0];
      console.log(account)
      localStorage.setItem('account', JSON.stringify(account));
      localStorage.setItem('responseData', JSON.stringify(data));

      // פונקציה לעדכון מצב ולניווט
      const handleStatus = (status:string, navigatePath:string) => {
        setIdExists(status !== "לא פעיל");
        localStorage.setItem('idExists', JSON.stringify(status !== "לא פעיל"));
        
        if (status === "לא פעיל") {
          console.log('שיבוץ מסוג צהרון לא קיים/לא פעיל');
          setShowMessage(true);
          setTimeout(() => {
            setShowMessage(false);
            navigate(`/${authorityName}`);
          }, 2000);
        } else {
          console.log('קיים שיבוץ מסוג צהרון');
          navigate(navigatePath);
        }
      };
    
      if (data) {
        handleStatus(data.status, `/${authorityName}/paymentsPage`);
      } else {
        console.log('לא קיים שיבוץ מסוג צהרון');
        handleStatus("לא פעיל", `/${authorityName}`);
      }
    } catch (error) {
      console.error('שגיאה בביצוע הבקשה:', error);
    }    };

  
  
  return (
    <div className="dashboard-container">
      {!authorityName && (
        <div className="error-message">
          <h1> url יש לרשום את שם הרשות באנגלית בסוף ה</h1>
        </div>
      )}
      {authorityName && (
        <>
        <Typography align="center" variant="h1">
            ברוכים הבאים למשפחת צהרוני {authority?.he}
          </Typography>

        {authorityName==="lod" && (
          <>
          <br />
          <Typography align="center" variant="h3">
          הורים יקרים,
          בדף זה תוכלו לשלם הפרשי מחיר וחובות לצהרוני עיריית לוד בהתאם לשינויי המחיר שפורסמו
          </Typography>
          <Typography align="center" variant="h5">
          מחיר צהרוני ניצנים (לא כולל גני חובה) - 1300 שח
          <br />
          אנא הכניסו מס ת.ז של ילדיכם לבדיקת יתרת התשלום שלכם
              </Typography>
              <br />
              </>
        )}
        
            <div className="id-input-container">
            {authorityName!=="lod" && (
              <Typography align="center" variant="h5">
                אנא הכנס מספר תעודת זהות
              </Typography>
            )}
              <TextField
                  value={idNumber}
                  onChange={handleIdChange}
                  label="תעודת זהות"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  inputProps={{ maxLength: 9, inputMode: 'numeric' }} // הגבלת תווים והגדרת inputMode לנומרי
                  error={!!error}
                  helperText={error}
                />
                {/* הצגת ההודעה אם showMessage הוא true */}
                    {showMessage && (
                    <div style={{ color: "red", fontWeight: "bold", fontSize: "24px", textAlign: "center", marginBottom: "20px" }}>
                        לא קיים רישום פעיל במערכת, הנך מועבר לתהליך הרשמה
                    </div>
                    )}
              {/* {error && <p style={{ color: 'red' }}>{error}</p>} */}
              <Button variant="contained" color="primary" onClick={handleIdSubmit} disabled={!!error}>
                שלח
              </Button>
            </div>
             {/* דיאלוג שמופיע אם field21 שווה 0 */}
             <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
                <DialogContent>
                    <DialogContentText>
                    {dialogContent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                {dialogActions}
                </DialogActions>
            </Dialog>
        </>
      )}
    </div>
  );
};

export default CheckId;
