// Layout.tsx
import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { AdminProvider } from '../../AdminContext'; // עדכון הנתיב בהתאם
import '../../components/pages/NoScroll.css'; 

const Layout: React.FC = () => {
  return (
    <AdminProvider>
      <div className="app-container">
        <Header />
        <main>
          <Outlet />
        </main>
        <Footer />
      </div>
    </AdminProvider>
  );
};

export default Layout;
