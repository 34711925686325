import React from 'react';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import appConfig from '../../utils/appConfig';
import './Header.css'; // Ensure you import your CSS file

// Import images using ES6 import syntax
import eliadLogo from '../../assets/images/eliadLogo.png';
import lodLogo from '../../assets/images/lodLogo.png';
import defaultLogo from '../../assets/images/defaultLogo.png';

const Header: React.FC = () => {
  const { state } = useLocation();
  const account = state?.account;

  // Function to get the image URL based on the authority in the URL
  const getImageByAuthority = (url: string): string => {
    if (url.includes('eliad')) return eliadLogo;
    if (url.includes('lod')) return lodLogo;
    return defaultLogo;
  };

  // Extract the URL and determine the appropriate logo
  const currentURL = window.location.href;
  const logo = getImageByAuthority(currentURL);

  return (
    <div className="header-container">
      <div className="header">
        {account?.logo_link && (
          <img 
            src={account.logo_link} 
            alt={account.name_to_web} 
            style={{ maxWidth: '100px', maxHeight: '100px' }} 
          />
        )}
        <img 
          className="image" 
          style={{ marginTop: '10px', width: '120px', height: '100px' }} 
          src={logo} 
          alt="logo" 
        />
        {/* Uncomment if you need to display the title */}
        {/* {shouldDisplayTitle && (
          <Typography variant="h3">{appConfig.page_title()}</Typography>
        )} */}
      </div>
    </div>
  );
};

export default Header;
