import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import appConfig from '../../utils/appConfig';

const ThankYouPage: React.FC = () => {
  const location = useLocation();
  const [creditcardPaymentData, setCreditcardPaymentData] = useState({
    response: '',
    userId: '',
    sum: '',
    email: '',
    confirmationCode: ''
  });

  const [formData, setFormData] = useState({});
  const [afternoon, setAfternoon] = useState({});
  const [account, setAccount] = useState({});
  const [payType, setPayType] = useState('');
  const [responseData, setResponseData] = useState<any>(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    // קריאת נתוני התשלום מה-URL ושמירה ב-`creditcardPaymentData` state
    setCreditcardPaymentData({
      response: params.get('response') || '',
      userId: params.get('userId') || '',
      sum: params.get('sum') || '',
      email: params.get('email') || '',
      confirmationCode: params.get('confirmationCode') || ''
    });

    // קריאת המידע מה-localStorage ושמירתו ב-state
    const storedFormData = localStorage.getItem('formData');
    const storedAfternoon = localStorage.getItem('afternoon');
    const storedAccount = localStorage.getItem('account');
    const storedPayType = localStorage.getItem('cc_type');
    const storedResponseData = localStorage.getItem('responseData');

    if (storedFormData) {
      setFormData(JSON.parse(storedFormData));
    }
    if (storedAfternoon) {
      setAfternoon(JSON.parse(storedAfternoon));
    }
    if (storedAccount) {
      setAccount(JSON.parse(storedAccount));
    }
    if (storedPayType) {
      setPayType(storedPayType);
    }
    if (storedResponseData) {
      const parsedData = JSON.parse(storedResponseData);
      console.log(parsedData);
      setResponseData(parsedData);
    }
  }, [location.search]);

  const productType="צהרון"

  useEffect(() => {
    // שילוב כל הפרמטרים מה-URL, נתוני התשלום והנתונים מה-localStorage
    const dataToSend = {
      payType,
      productType,
      creditcardPaymentData,
      account,
      formData,
      afternoon,
      responseData
    };

    // שליחת הנתונים לשרת אם הקוד תגובה הוא 000
    if (creditcardPaymentData.response === '000') {
      console.log(dataToSend);
      axios.post(appConfig.tempPaymentUrl, dataToSend)
        .then(response => {
          console.log('Data sent successfully:', response.data);
        })
        .catch(error => {
          console.error('Error sending data:', error);
        });
    }
  }, [creditcardPaymentData, formData, afternoon]);

  console.log('Payment Data:', creditcardPaymentData);
  console.log('Form Data:', formData);
  console.log('Afternoon Data:', afternoon);

  return (
    <Box sx={{ textAlign: 'center', marginTop: 4 }}>
      {creditcardPaymentData.response === '000' ? (
        <>
          <Typography variant="h4" sx={{ color: '#7b1213', fontWeight: 'bold' }}>
            התשלום עבר בהצלחה, תודה
          </Typography>
          <Typography variant="h6" sx={{ color: '#7b1213', fontWeight: 'normal' }}>
            מספר אישור {creditcardPaymentData.confirmationCode}
          </Typography>
          <Typography variant="h6" sx={{ color: '#7b1213', fontWeight: 'normal' }}>
            אישור תשלום ישלח במייל
          </Typography>
        </>
      ) : (
        <Typography variant="h6" sx={{ color: '#7b1213', fontWeight: 'normal' }}>
          ארעה שגיאה בתהליך התשלום, אנא נסו שוב, באם התקלה חוזרת פנו אלינו בטופס צור קשר
        </Typography>
      )}
    </Box>
  );
};

export default ThankYouPage;
