import React, { useState } from 'react';
import { Grid, Typography, Modal, Box, Card, CardContent, TextField, Button } from '@mui/material';
import { useAdmin } from '../../AdminContext'; // עדכון הנתיב בהתאם
import appConfig from '../../utils/appConfig';
import logo from '../../assets/images/logo.png';
import pci from '../../assets/images/pci.png';
import './Footer.css';
import terms_approval from '../../utils/termsAproval';

const Footer: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [adminOpen, setAdminOpen] = useState<boolean>(false);
  const [fileDialogOpen, setFileDialogOpen] = useState<boolean>(false); // למעקב אחרי מצב הדיאלוג של הקבצים
  const [fileList, setFileList] = useState<{ name: string; url: string }[]>([]); // רשימת קבצים
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const { isAdmin, setIsAdmin } = useAdmin();

  const handleAdminLogin = () => {
    if (username === 'hagay' && password === '1234') {
      setIsAdmin(!isAdmin);
      setAdminOpen(false);
    } else {
      alert('שם משתמש או סיסמא שגויים');
    }
  };

  const handleFetchFiles = async () => {
    try {
      const response = await fetch(`${appConfig.apiUrls.baseUrl}/zohoAccounts/savedFiles/list`);
      if (response.ok) {
        const data = await response.json();
        setFileList(data.files); // שמירת רשימת הקבצים במצב
        setFileDialogOpen(true); // פתיחת הדיאלוג להצגת הכפתורים
      } else {
        alert('התרחשה שגיאה בעת קבלת רשימת הקבצים');
      }
    } catch (error) {
      console.error('שגיאה בקריאה לשרת:', error);
    }
  };

  const handleFileClick = (url: string) => {
    window.location.href = url; // מעבר לכתובת הקובץ
  };

  return (
    <>
      <div className="Footer">
        <Grid container spacing={2} justifyContent={"space-between"} alignItems={"center"} className="section-0">
          
          <Grid item lg={4} className="section-1">
            <Button variant="text" onClick={() => setAdminOpen(true)}  style={{
                color: 'black',
                textAlign: 'left',
                fontSize: '14px',
                position: 'fixed',
                bottom: '20px', // המרחק מהקצה התחתון של המסך
                left: '20px', // המרחק מהקצה השמאלי של המסך
              }}>
              כניסת מנהל
            </Button>

            {isAdmin && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleFetchFiles}
                sx={{ marginLeft: '20px', fontSize: '14px' }}
              >
                קובץ טפסי הרשמה
              </Button>
            )}

            {terms_approval.billagan_terms_approval &&
              <Typography 
                sx={{ textDecoration: "underline", fontSize: "14px", margin: 0, padding: 0, cursor: "pointer" }} 
                variant="body2" 
                onClick={() => setOpen(!open)}
              >
                תנאי שימוש
              </Typography>
            }
            
            {/* דיאלוג להצגת הקבצים */}
            <Modal open={fileDialogOpen} onClose={() => setFileDialogOpen(false)} aria-labelledby="file-dialog-title">
            <Box 
                sx={{ 
                  position: 'absolute', 
                  top: '50%', 
                  left: '50%', 
                  transform: 'translate(-50%, -50%)', 
                  width: '80%', 
                  bgcolor: 'background.paper', 
                  boxShadow: 24, 
                  p: 4,
                  display: 'flex',        // מרכז את התוכן בתוך ה-Box
                  flexDirection: 'column', // מיישר את התוכן בטור
                  alignItems: 'center'     // ממקם את התוכן במרכז אופקית
                }}
              >
                <Typography 
                  variant="h6" 
                  component="h2" 
                  sx={{ mb: 2, textAlign: 'center' }}
                >
                  רשימת קבצים זמינים
                </Typography>

                {fileList.length > 0 ? (
                  fileList.map((file, index) => (
                    <Button
                      key={index}
                      variant="outlined"
                      onClick={() => handleFileClick(file.url)}
                      sx={{ display: 'block', mb: 1, width: '100%' }}  // הכפתורים יהיו רחבים ומרכזיים
                    >
                      {file.name}
                    </Button>
                  ))
                ) : (
                  <Typography variant="body1" sx={{ textAlign: 'center' }}>אין קבצים זמינים</Typography>
                )}
              </Box>
            </Modal>

            <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
              <Box 
                sx={{ 
                  overflowY: "auto", 
                  position: 'absolute', 
                  top: '50%', 
                  left: '50%', 
                  transform: 'translate(-50%, -50%)', 
                  maxHeight: "90vh", 
                  maxWidth: "95vw", 
                  width: "800px" 
                }}
              >
                <Card>
                  <CardContent>
                    <Typography 
                      sx={{ textAlign: "left !important" }} 
                      variant="body1" 
                      dangerouslySetInnerHTML={{ __html: terms_approval.billagan_terms_approval }} 
                    />
                  </CardContent>
                </Card>
              </Box>
            </Modal>

            <Modal open={adminOpen} onClose={() => setAdminOpen(false)} aria-labelledby="admin-modal-title" aria-describedby="admin-modal-description">
              <Box 
                sx={{ 
                  position: 'absolute', 
                  top: '50%', 
                  left: '50%', 
                  transform: 'translate(-50%, -50%)', 
                  width: 400, 
                  bgcolor: 'background.paper', 
                  boxShadow: 24, 
                  p: 4, 
                  textAlign: 'center' // מרכז את כל התוכן בתוך ה-Box
                }}
              >
                <Typography id="admin-modal-title" variant="h6" component="h2">
                  {isAdmin ? 'ניתוק מנהל' : 'כניסת מנהל'}
                </Typography>
                <TextField 
                  fullWidth 
                  label="שם משתמש" 
                  variant="outlined" 
                  margin="normal"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <TextField 
                  fullWidth 
                  label="סיסמא" 
                  type="password" 
                  variant="outlined" 
                  margin="normal"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={handleAdminLogin}
                  sx={{ mt: 2 }} // הוסף מרווח עליון לכפתור
                >
                  {isAdmin ? 'יציאה' : 'כניסה'}
                </Button>
              </Box>
            </Modal>
          </Grid>

          <Grid item lg={4} className="section-3" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Typography fontSize={14}>תשלום מאובטח</Typography>
            <img src={pci} alt="PCI" width={80} height={50} style={{ objectFit: "contain" }} />
          </Grid>
          
          <Grid item className="section-3">
            <img src={logo} alt="Logo" width={180} style={{ marginRight: "auto" }} />
          </Grid>
        </Grid>
      </div>
      
      <Box sx={{ textAlign: 'left', padding: '5px', backgroundColor: '#bbb1b1'}}>
        <Typography sx={{ fontSize: "14px" ,textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: appConfig.service() }} />
      </Box>
    </>
  );
};

export default Footer;
