import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Container, Link } from '@mui/material';
import axios from 'axios';
import appConfig from '../../utils/appConfig';

const ThankYouPageHokBankPay: React.FC = () => {
  const location = useLocation();
  const { state } = location;
  const success = state?.success;

  // סטייט לאחסון המידע מה-localStorage
  const [formData, setFormData] = useState({});
  const [afternoon, setAfternoon] = useState({});
  const [account, setAccount] = useState({});
  const [hokBankFormData, setHokBankFormData] = useState({});

  useEffect(() => {
    // שליפת המידע מ-localStorage
    const storedFormData = localStorage.getItem('formData');
    const storedAfternoon = localStorage.getItem('afternoon');
    const storedCurrentFormData = localStorage.getItem('paymentData');
    const storedAccount = localStorage.getItem('account');
    

    if (storedFormData) {
      setFormData(JSON.parse(storedFormData));
    }
    if (storedAfternoon) {
      setAfternoon(JSON.parse(storedAfternoon));
    }
    if (storedCurrentFormData) {
      setHokBankFormData(JSON.parse(storedCurrentFormData));
    }
    if (storedAccount) {
      setAccount(JSON.parse(storedAccount));
    }

    // אם יש את כל הנתונים הדרושים, שליחה לשרת
    if (storedAccount&& storedFormData && storedAfternoon&& storedCurrentFormData) {
      const dataToSend = {
        payType: "הוראת קבע בנקאית",
        productType:"צהרון",
        account: JSON.parse(storedAccount),
        formData: JSON.parse(storedFormData),
        afternoon: JSON.parse(storedAfternoon),
        hokBankFormData: JSON.parse(storedCurrentFormData)
      };

      // שליחת הנתונים לשרת
      axios.post(appConfig.tempPaymentUrl, dataToSend)
        .then(response => {
          console.log('Data sent successfully:', response.data);
        })
        .catch(error => {
          console.error('Error sending data:', error);
        });
    }
  }, []);

  return (
    <Container style={{ textAlign: 'center', marginTop: '50px', direction: 'rtl' }}>
      <Typography variant="h4">
        {success ? 'טפסי ההרשמה נשלחו בהצלחה!' : 'ארעה תקלה בשליחה הטפסים, אנא נסה מאוחר יותר'}
      </Typography>
    </Container>
  );
};

export default ThankYouPageHokBankPay;
