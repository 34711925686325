import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import './Form.css';
import appConfig from '../../utils/appConfig';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import terms_approval from '../../utils/termsAproval';
import { useAdmin } from '../../AdminContext'; 

interface FormData {
  studentId: string;
  birthDate: string;
  firstName: string;
  lastName: string;
  gender: string;
  healthCare: string;
  address: {
    city: string;
    street: string;
    number: string;
  };
  parents: {
    parent1: string;
    maritalStatus:string,
    parentName: string;
    parentId: string;
    parentPhone: string;
    parent2: string;
    additionalParentName: string;
    additionalParentId: string;
    additionalParentPhone: string;
    email: string;
  };
  healthDeclaration: {
    healthIssue: string;
    healthIssueDetails: string;
    foodSensitivity: boolean;
    foodDetails: string;
    foodSensitivityDocument: {
      file: File;
      base64: string;
      sha256: string;
    } | null;
    foodPreference: string;
    otherFoodPreference:string;
  };
  collection: {
    selfReturn: string;
    authorizedPersons: {
      name: string;
      phone: string;
    }[];
  };
  publication: {
    photoPermission: boolean;
    generalNote: string;
    arrivalMethod: boolean;
  };
  minAccepted: boolean;
  hasAssistant: boolean;
    assistantType:string;
  termsAccepted: boolean;
}

const Form: React.FC = () => {
  const location = useLocation();
  const account = location.state?.account;
  const afternoon = location.state?.afternoon;
  const initialFormData: FormData = {
    studentId: '',
    birthDate: '',
    firstName: '',
    lastName: '',
    gender: '',
    healthCare: '',
    address: {
      city: '',
      street: '',
      number: ''
    },
    parents: {
      parent1: '',
      maritalStatus:'',
      parentName: '',
      parentId: '',
      parentPhone: '',
      parent2: '',
      additionalParentName: '',
      additionalParentId: '',
      additionalParentPhone: '',
      email: '',
    },
    healthDeclaration: {
      healthIssue: '',
      healthIssueDetails: '',
      foodSensitivity: false,
      foodDetails: '',
      foodSensitivityDocument: null,
      foodPreference: '',
      otherFoodPreference:''
    },
    collection: {
        selfReturn: '',
        authorizedPersons: [{ name: '', phone: '' }, { name: '', phone: '' }]
      },
      publication: {
      photoPermission:  false,
      generalNote: '',
      arrivalMethod: false
    },
    minAccepted: false,
    hasAssistant: false,
    assistantType:'',
    termsAccepted: false
  };
  // const initialFormData: FormData = {
  //   studentId: '123456789',
  //   birthDate: '2010-04-15',
  //   firstName: 'יוסי',
  //   lastName: 'כהן',
  //   gender: 'זכר',
  //   healthCare: 'קופת חולים כללית',
  //   address: {
  //     city: 'תל אביב',
  //     street: 'דיזנגוף',
  //     number: '50'
  //   },
  //   parents: {
  //     parent1: 'אבא',
  //     parentName: 'אבי כהן',
  //     parentId: '987654321',
  //     parentPhone: '0501234567',
  //     parent2: '',
  //     additionalParentName: '',
  //     additionalParentId: '',
  //     additionalParentPhone: '',
  //     email: 'example@mail.com',
  //   },
  //   healthDeclaration: {
  //     healthIssue: 'noIssues',
  //     healthIssueDetails: '',
  //     foodSensitivity: false,
  //     foodDetails: '',
  //     foodSensitivityDocument: null,
  //     foodPreference: '',
  //     otherFoodPreference: ''
  //   },
  //   collection: {
  //     selfReturn: 'false',
  //     authorizedPersons: [
  //       { name: 'דוד כהן', phone: '0501111111' },
  //       { name: 'שרה לוי', phone: '0502222222' }
  //     ]
  //   },
  //   publication: {
  //     photoPermission: false,
  //     generalNote: '',
  //     arrivalMethod: false
  //   },
  //   minAccepted: true,
  //   hasAssistant: false,
  //   assistantType: '',
  //   termsAccepted: true
  // };
  

  const [formData, setFormData] = useState<FormData>(initialFormData);
  const [dialogMessage, setDialogMessage] = useState('');
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogSelection, setDialogSelection] = useState("");

  const navigate = useNavigate();
  const studentIdRef = React.useRef<HTMLDivElement>(null);

  const { authorityName } = useParams<{ authorityName: string }>();

  const authority = appConfig.authorities.find(auth => auth.en === authorityName);

  const { isAdmin } = useAdmin();

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = event.target;
    const finalValue = type === 'checkbox' ? (event.target as HTMLInputElement).checked : value;
  
    const hasError = validateInput(name, value);
    if (hasError) {return};
  
    setFormData(prevState => {
      const updatedFormData = { ...prevState };
  
      if (name.includes('.')) {
        const keys = name.split('.');
  
        let field = updatedFormData as any;
        for (let i = 0; i < keys.length - 1; i++) {
          const key = keys[i];
          if (key.includes('[')) {
            const [arrayKey, index] = key.split(/[\[\]]/).filter(Boolean);
            field = field[arrayKey][parseInt(index)];
          } else {
            field = field[key];
          }
        }
  
        const lastKey = keys[keys.length - 1];
        field[lastKey] = finalValue;
      } else {
        (updatedFormData as any)[name] = finalValue;
      }
  
      return updatedFormData;
    });
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
  
    if (file) {
      const base64 = await getBase64(file);
      const sha256 = await getSHA256(base64);
  
      setFormData((prevData) => ({
        ...prevData,
        healthDeclaration: {
          ...prevData.healthDeclaration,
          foodSensitivityDocument: {
            file,
            base64,
            sha256,
          },
        },
      }));
    }
  };
  
  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };
  
  const getSHA256 = async (base64: string): Promise<string> => {
    const buffer = new TextEncoder().encode(base64);
    const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  };
  
  
  

  const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    validateInput(name, value, true);
  };

  const validateInput = (name: string, value: string, onBlur: boolean = false): boolean => {
    let hasError = false;
  
    const setError = (message: string) => {
      setErrors(prevErrors => ({ ...prevErrors, [name]: message }));
      hasError = true;
    };
  
    const clearError = () => {
      setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
      hasError = false;
    };
  
    if (name.includes("Id")) {
      if (!/^\d*$/.test(value)) {
        setError('ניתן להקיש ספרות בלבד');

      }
      if(!isAdmin){
        if (onBlur && value.length === 9) {
          // לוגיקת בדיקת תקינות תעודת זהות ישראלית
          const isValid = Array.from(value, Number).reduce((counter, digit, i) => {
            const step = digit * ((i % 2) + 1);
            return counter + (step > 9 ? step - 9 : step);
          }, 0) % 10 === 0;
      
          if (isValid) {
            clearError();
          } else {
            setError('מספר תעודת זהות לא תקין');
          }
        } else if (onBlur && value.length !== 9) {
          setError('מספר תעודת זהות צריך להיות באורך 9 ספרות');
        }

      }
      //  else if (onBlur && value.length === 9) {
      //   // לוגיקת בדיקת תקינות תעודת זהות ישראלית
      //   const isValid = Array.from(value, Number).reduce((counter, digit, i) => {
      //     const step = digit * ((i % 2) + 1);
      //     return counter + (step > 9 ? step - 9 : step);
      //   }, 0) % 10 === 0;
    
      //   if (isValid) {
      //     clearError();
      //   } else {
      //     setError('מספר תעודת זהות לא תקין');
      //   }
      // } else if (onBlur && value.length !== 9) {
      //   setError('מספר תעודת זהות צריך להיות באורך 9 ספרות');
      // }
      console.log(errors);
    }   
    if (name==="parents.parentId") {
      if(formData.studentId===formData.parents.parentId ){
        setError('מספר תעודת זהות של ההורה לא יכול להיות זהה למספר תעודת זהות של הילד ');
        formData.parents.parentId=''
      }

    } 

  
    if (name.includes("Phone") || name.includes("phone")) {
      if (!/^\d*$/.test(value)) {
        setError('ניתן להקיש ספרות בלבד');
      } else if (value.startsWith('05') && value.length === 10) {
        clearError();
      } else if (onBlur && (!value.startsWith('05') || value.length !== 10)) {
        setError('מספר טלפון לא תקין, יש להקליד עשר ספרות בתבנית: 0501234567');
      }
    }
  
    return hasError;
  };
  

//   const handleButtonClick = async (fieldValue: string) => {
//     if (fieldValue === "") {
//       setErrors(prevErrors => ({ ...prevErrors, paymentMethod: 'Field is required' }));
//     } else {
//       // שליחת הנתונים ל-appConfig.tempUrl
//       try {
//         const dataToSend = { account, afternoon, formData };
//         const response = await axios.post(appConfig.tempFormUrl, dataToSend);
//         console.log('Data sent successfully:', response.data);
//       } catch (error) {
//         console.error('Error sending data:', error);
//       }
  
//       setSelectedPaymentMethod(fieldValue);
//       if(selectedPaymentMethod === "pay_hok_bank") {
//         navigate(`/hokBankPay`, { state: { account, afternoon, formData } });
//       }
//       if(selectedPaymentMethod === "pay_hok_cc") {
//         navigate(`/creditcardHok`, { state: { account, afternoon, formData } });
//       }
//       if(selectedPaymentMethod === "pay_cc") {
//         navigate(`/creditcardPay`, { state: { account, afternoon, formData } });
//       }
//       setErrors(prevErrors => {
//         const { paymentMethod, ...rest } = prevErrors;
//         return rest;
//       });
//     }
//   };
  
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
    }
  
    if (Object.keys(errors).some(key => errors[key])) {
      setDialogMessage('שגיאה במילוי אחד השדות');
      setOpenSuccessDialog(true); // יפתח את הדיאלוג במקרה של שגיאה
      return;
    }
    
    const fileName = `${formData.studentId}_${new Date().toLocaleDateString('en-GB').replace(/\//g, '-')}_${new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' }).replace(/[:]/g, '-')}`;

    console.log(fileName)
  
    if (selectedPaymentMethod === "") {
        setErrors(prevErrors => ({ ...prevErrors, paymentMethod: 'Field is required' }));
      } else {
        // שליחת הנתונים ל-appConfig.tempUrl
        try {
          // שמירה ב-localStorage
          localStorage.setItem('account', JSON.stringify(account));
          localStorage.setItem('afternoon', JSON.stringify(afternoon));
          localStorage.setItem('formData', JSON.stringify(formData));
        
          console.log('Data saved in localStorage successfully');
    
          // שליחת הנתונים לשרת לשמירת קובץ
          const response = await fetch(`${appConfig.apiUrls.baseUrl}/zohoAccounts/saveFormData`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ fileName, formData }), // שליחת שם הקובץ ונתוני ה-form
          });
    
          if (!response.ok) {
            throw new Error('Failed to save form data on server');
          }
    
          const data = await response.json();
          console.log('Server response:', data); // התגובה מהשרת
    
        } catch (error) {
          console.error('Error saving data to server:', error);
        }

        if(selectedPaymentMethod === "pay_hok_bank") {
          navigate(`/${authority?.en}/hokBankPay`, { state: { account, afternoon, formData } });
        }
        if(selectedPaymentMethod === "pay_hok_cc") {
          localStorage.setItem('cc_type', "creditcardHok");
          navigate(`/${authority?.en}/creditcardHok`, { state: { account, afternoon, formData } });
        }
        if(selectedPaymentMethod === "pay_cc") {
          localStorage.setItem('cc_type', "creditcard");
          navigate(`/${authority?.en}/creditcardPay`, { state: { account, afternoon, formData } });
        }
        if(selectedPaymentMethod === "cash_or_check") {
          navigate(`/${authority?.en}/cashOrCheckPay`, { state: { account, afternoon, formData } });
        }
         // Disable the button for 10 seconds
          setTimeout(() => {
            setIsLoading(false);
          }, 10000);

        setErrors(prevErrors => {
          const { paymentMethod, ...rest } = prevErrors;
          return rest;
        });
      }


  };
  
  
  
  const renderErrorMessage = (fieldName: string) => {
    return errors[fieldName] ? (
      <div className="error-message" style={{ color: 'red' }}>
        {errors[fieldName]}
      </div>
    ) : null;
  };
  


  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };
  
  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDialogClose2 = () => {
    if (dialogSelection === "כן") {
      setFormData({
        ...formData,
        parents: {
          ...formData.parents,
          maritalStatus: "גרוש",
        },
      });
    } else {
      setFormData({
        ...formData,
        parents: {
          ...formData.parents,
          maritalStatus: "נשוי",
        },
      });
    }
    setShowDialog(false);
  };
  const handleDialogOpen2 = () => {
    setShowDialog(true);
  };

  const calculateMonthsToJune = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // מחזיר את החודש הנוכחי כמספר (0-11)
    const currentYear = currentDate.getFullYear();
  
    // מספר החודשים עד יוני הבא כולל החודש הנוכחי ויוני הבא
    const juneMonth = 5; // 5 מייצג את יוני (0-11)
    const juneYear = currentMonth <= juneMonth ? currentYear : currentYear + 1;
  
    // חישוב מספר החודשים
    let monthsDifference = (juneYear - currentYear) * 12 + (juneMonth - currentMonth) + 1;

    monthsDifference = monthsDifference > 10 ? 10 : monthsDifference;

    return monthsDifference;
  }; 
  
const mounts = calculateMonthsToJune(); 
  
const termsHtml = (fieldValue: string) => {
  const authorityName = authority?.en;

  if (!authorityName) {
    throw new Error('Authority name is not defined');
  }

  // Find keys that include the authority name
  const matchingKeys = Object.keys(terms_approval).filter(key => key.includes(authorityName));

  if (matchingKeys.length === 0) {
    throw new Error(`No matching terms approval found for authority: ${authorityName}`);
  }

  let htmlContent;

  if (matchingKeys.length > 1) {
    // If there are multiple matching keys, check the account name to determine the correct content
    if (authorityName === "eliad" && account.name_to_web === "צהרונים בני שמעון") {
      htmlContent = terms_approval["eliad_bneiShimon_terms_approval"];
    } else {
      htmlContent = terms_approval["eliad_general_terms_approval"];
    }
  } else {
    // If there is only one matching key, use it
    htmlContent = terms_approval[matchingKeys[0]];
  }

  // Replace the fieldValue in the HTML content
  return htmlContent.replace('{fieldValue}', fieldValue);
};

  return (
    <div className="form">
        
      <form onSubmit={handleSubmit} className="form-container">
      <div style={{ textAlign: 'center' }}>
      <h1>טופס רישום עבור צהרון {afternoon.field1}</h1>
      <div className="payment-info">
      {afternoon.field22  ?
      <div>
          <p>מחיר חודשי: {afternoon.field22}</p>
          <p> חודשי חיוב: {mounts}</p>
          <p>עלות שנתית: {afternoon.field22 * mounts}</p>
          </div>
      :
      <p>עלות שנתית: {afternoon.Total_annual_price}</p>
      }
      </div>
    </div>
        <div className="section-title-container">
          <h2 className="section-title">פרטי המשתתף</h2>
          <span className="short-line"></span>
        </div>
        <div className="form-section">
        <div className="form-group" ref={studentIdRef}>
            <label>מספר תעודת זהות:*</label>
            <input
              type="text"
              name="studentId"
              placeholder="מספר תעודת זהות"
              value={formData.studentId}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
            {renderErrorMessage('studentId')}
          </div>
          <div className="form-group">
            <label>תאריך לידה:*</label>
            <input type="date" name="birthDate" value={formData.birthDate} onChange={handleChange} max={getCurrentDate()} required/>
          </div>
          <div className="form-group">
            <label>שם פרטי:*</label>
            <input type="text" name="firstName" placeholder="שם פרטי" value={formData.firstName} onChange={handleChange} required/>
          </div>
          <div className="form-group">
            <label>שם משפחה:*</label>
            <input type="text" name="lastName" placeholder="שם משפחה" value={formData.lastName} onChange={handleChange} required/>
          </div>
          <div className="form-group">
            <label>מין:*</label>
            <select name="gender" value={formData.gender} onChange={handleChange} required>
              <option value="" disabled>בחר מין</option>
              <option value="זכר">זכר</option>
              <option value="נקבה">נקבה</option>
            </select>
          </div>
          <div className="form-group">
            <label>קופת חולים:*</label>
            <select 
              name="healthCare" 
              value={formData.healthCare} 
              onChange={handleChange} required
            >
              <option value="" disabled>בחר</option>
              <option value="כללית">כללית</option>
              <option value="מכבי">מכבי</option>
              <option value="לאומית">לאומית</option>
              <option value="מאוחדת">מאוחדת</option>
            </select>
          </div>
          <div className="form-group">
            <label>כתובת:*</label>
            <input type="text" name="address.city" placeholder="עיר/יישוב" value={formData.address.city} onChange={handleChange} required/>
            <input type="text" name="address.street" placeholder="רחוב" value={formData.address.street} onChange={handleChange}required />
            <input type="text" name="address.number" placeholder="מספר בית" value={formData.address.number} onChange={handleChange} required/>
          </div>
        </div>
        <div className="section-title-container">
          <h2 className="section-title">פרטי ההורים</h2>
          <span className="short-line"></span>
        </div>
        <div className="form-section">
        <div className="form-group">
  <label>ממלא הטופס:*</label>
  <div className="radio-group">
    <label>
      <input
        type="radio"
        name="parents.parent1"
        value="אבא"
        checked={formData.parents.parent1 === "אבא"}
        onChange={handleChange}
        required
      />
      אבא
    </label>
    <label>
      <input
        type="radio"
        name="parents.parent1"
        value="אמא"
        checked={formData.parents.parent1 === "אמא"}
        onChange={handleChange}
        required
      />
      אמא
    </label>
  </div>
</div>

<div className="form-group">
<p>
        ניתן לעדכן{" "}
        <span
          style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }}
          onClick={handleDialogOpen2}
        >
          כאן
        </span>{" "}
        פרטי תשלום בהתאם למצב משפחתי
      </p>
 
         <div className="form-group">

   
   <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
         <DialogTitle style={{ textAlign: 'center' }}> פיצול תשלום עבור הורה יחיד</DialogTitle>
           <DialogContent>
             <p>
               האם תרצה לפצל את התשלום ולשלם כרגע רק חצי מהעלות השנתית? ({afternoon.field22 * mounts})
             </p>
             <RadioGroup
               value={dialogSelection}
               onChange={(e) => setDialogSelection(e.target.value)}
             >
               <div style={{ display: 'grid', justifyContent: 'right' }}>
               <FormControlLabel 
                  value="כן" 
                  control={<Radio />} 
                  label="כן" 
                  labelPlacement="start" 
                />
                <FormControlLabel 
                  value="לא" 
                  control={<Radio />} 
                  label="לא" 
                  labelPlacement="start" 
                />
              </div>
             </RadioGroup>
           </DialogContent>
           <DialogActions style={{ justifyContent: 'center' }}>
             <Button onClick={handleDialogClose2}>סגור</Button>
           </DialogActions>
         </Dialog>
       </div>
    </div>
          <div className="form-group">
            <label>שם הורה:*</label>
            <input type="text" name="parents.parentName" placeholder="שם הורה" value={formData.parents.parentName} onChange={handleChange} required/>
          </div>
          <div className="form-group">
            <label>מספר תעודת זהות הורה:*</label>
            <input type="text" name="parents.parentId" placeholder="מספר תעודת זהות הורה" value={formData.parents.parentId} onBlur={handleBlur} onChange={handleChange} required/>
            {renderErrorMessage('parents.parentId')}
          </div>
          <div className="form-group">
            <label>נייד הורה:*</label>
            <input type="text" name="parents.parentPhone" placeholder="נייד הורה" value={formData.parents.parentPhone}  onChange={handleChange} required
              onBlur={handleBlur}
            />
            {renderErrorMessage('parents.parentPhone')}
          </div>
          <div className="form-group">
                 <label>הורה שני:</label>
                  <label>
                    <input
                      type="radio"
                      name="parents.parent2"
                      value="אבא"
                      checked={formData.parents.parent2 === "אבא"}
                      onChange={handleChange}
                    />
                    אבא
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="parents.parent2"
                      value="אמא"
                      checked={formData.parents.parent2 === "אמא"}
                      onChange={handleChange}
                    />
                    אמא
                  </label>
                </div>
          <div className="form-group">
            <label>שם הורה:</label>
            <input type="text" name="parents.additionalParentName" placeholder="שם הורה" value={formData.parents.additionalParentName} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label>מספר תעודת זהות הורה:</label>
            <input type="text" name="parents.additionalParentId" placeholder="מספר תעודת זהות הורה" value={formData.parents.additionalParentId} onBlur={handleBlur} onChange={handleChange} />
            {renderErrorMessage('parents.additionalParentId')}
          </div>
          <div className="form-group">
            <label>נייד הורה:</label>
            <input type="text" name="parents.additionalParentPhone" placeholder="נייד הורה" value={formData.parents.additionalParentPhone}  onChange={handleChange}
              onBlur={handleBlur}
            />
            {renderErrorMessage('parents.additionalParentPhone')}
          </div>
          <div className="form-group">
            <label>דוא"ל ליצירת קשר ושליחת מסמכים:*</label>
            <input type="email" name="parents.email" placeholder="דואר אלקטרוני" value={formData.parents.email} onChange={handleChange} required/>
          </div>
        </div>
        <div className="section-title-container">
          <h2 className="section-title">הצהרת בריאות ופרטים נוספים</h2>
          <span className="short-line"></span>
        </div>
        <div className="form-section">
          <div className="form-group">
            <label>
              <input type="radio" name="healthDeclaration.healthIssue" value="noIssues" checked={formData.healthDeclaration.healthIssue === 'noIssues'} onChange={handleChange} required/>
              הנני מצהיר כי אין למשתתף/ת כל מגבלה רפואית ו/או רגישות והנני מתחייב/ת להודיע ל{authority?.he} על כל שינוי במצב הבריאותי של המשתתף/ת*
            </label>
            <label>
              <input type="radio" name="healthDeclaration.healthIssue" value="hasIssues" checked={formData.healthDeclaration.healthIssue === 'hasIssues'} onChange={handleChange} required/>
              יש למשתתף/ת מגבלה רפואי ו/או רגישות
            </label>
          </div>
          {formData.healthDeclaration.healthIssue === 'hasIssues' && (
              <input type="text" name="healthDeclaration.healthIssueDetails" placeholder="פרט" value={formData.healthDeclaration.healthIssueDetails} onChange={handleChange} required/>
            )}
         <div className="form-group">
  <label>
    <input type="checkbox" name="healthDeclaration.foodSensitivity" checked={formData.healthDeclaration.foodSensitivity} onChange={handleChange} />
    קיימת רגישות למזון
  </label>
  {formData.healthDeclaration.foodSensitivity && (
    <>
      <input type="text" name="healthDeclaration.foodDetails" placeholder="פרט" value={formData.healthDeclaration.foodDetails} onChange={handleChange} required/>
      <div>
        <label htmlFor="foodSensitivityDocument">יש לצרף אישור רפואי על רגישות למזון*</label>
        <input type="file" name="healthDeclaration.foodSensitivityDocument" onChange={handleFileChange} required/>
      </div>
    </>
  )}
</div>

          <div className="form-group">
  <label>הזנה/ אוכל מיוחד:</label>
  <select 
    name="healthDeclaration.foodPreference" 
    value={formData.healthDeclaration.foodPreference} 
    onChange={handleChange} 
  >
    <option value="" disabled>בחר</option>
    <option value="רגיל">רגיל</option>
    <option value="ללא גלוטן">ללא גלוטן</option>
    <option value="צמחוני">צמחוני</option>
    <option value="טבעוני">טבעוני</option>
    <option value="ללא אלרגניים">ללא אלרגניים</option>
    <option value="אחר">אחר</option>
  </select>

  {formData.healthDeclaration.foodPreference === 'אחר' && (
    <div className="form-group">
      <label>פרט:</label>
      <textarea 
        name="healthDeclaration.otherFoodPreference" 
        value={formData.healthDeclaration.otherFoodPreference} 
        onChange={handleChange} 
      />
    </div>
  )}
</div>

        </div>
        {authority?.en === "eliad" && (
<>
        <div className="section-title-container">
          <h2 className="section-title">איסוף</h2>
          <span className="short-line"></span>
        </div>
        <div className="form-section">
  <div className="form-group">
    <label>
      <input
        type="radio"
        name="collection.selfReturn"
        value="yes"
        checked={formData.collection.selfReturn === "yes"}
        onChange={handleChange} 
      />
      אני מאשר כי בני/בתי יחזור/תחזור הביתה לבד
    </label>
    <label>
    <Typography variant="body1">
      <input
        type="radio"
        name="collection.selfReturn"
        value="no"
        checked={formData.collection.selfReturn === "no"}
        onChange={handleChange} 
      />

        אני מאשר כי בני/בתי יחזור/תחזור הביתה עם מורשים ספציפיים, <strong>בנוסף להורי הילד/ה</strong>
      </Typography>
    </label>
  </div>

  {/* הצגת שדות מורשים רק אם נבחר האופציה השנייה */}
  {formData.collection.selfReturn === "no" && (
    <>
      <div className="form-group">
        <label>שם מורשה 1:*</label>
        <input
          type="text"
          name="collection.authorizedPersons[0].name"
          placeholder="שם מורשה 1"
          value={formData.collection.authorizedPersons[0].name}
          onChange={handleChange} required
        />
        <input
          type="text"
          name="collection.authorizedPersons[0].phone"
          placeholder="נייד"
          value={formData.collection.authorizedPersons[0].phone}
          onChange={handleChange} required
          onBlur={handleBlur}
        />
        {renderErrorMessage('collection.authorizedPersons[0].phone')}
      </div>
      <div className="form-group">
        <label>שם מורשה 2:</label>
        <input
          type="text"
          name="collection.authorizedPersons[1].name"
          placeholder="שם מורשה 2"
          value={formData.collection.authorizedPersons[1].name}
          onChange={handleChange}

        />
        <input
          type="text"
          name="collection.authorizedPersons[1].phone"
          placeholder="נייד"
          value={formData.collection.authorizedPersons[1].phone}
          onChange={handleChange}
          onBlur={handleBlur}
        />
           {renderErrorMessage('collection.authorizedPersons[1].phone')}
      </div>
    </>
  )}
</div>
</>
)}
{authority?.en === "eliad" && (
<div className="form-group">
        <label>
              <input type="checkbox" name="publication.arrivalMethod" checked={formData.publication.arrivalMethod} onChange={handleChange} />
              בני/ביתי מגיעים וחוזרים בהסעה מהצהרון
            </label>
       </div>
)}
        <div className="section-title-container">
          <h2 className="section-title">פרטים נוספים</h2>
          <span className="short-line"></span>
        </div>
        <div className="form-section">
        {authority?.en === "eliad" && (

        <div className="form-group">
        <label>
              <input type="checkbox" name="minAccepted" checked={formData.minAccepted} onChange={handleChange} required/>
              ידוע לי כי פתיחת קבוצה מותנית במינימום נרשמים, עדכון על קיום הפעילות יתבצע ע"י הרכז/ת סמוך לתחילת השנה.*
            </label>
       </div>
        )}
        <div className="form-group">
        <label>
              <input type="checkbox" name="publication.photoPermission" checked={formData.publication.photoPermission} onChange={handleChange} />
              אני מאשר לפרסם תמונות של הנרשם/ת רק בקבוצה הגן/הכתה
            </label>
       </div>

          <div className="form-group">
  <label>
    <input
      type="checkbox"
      name="hasAssistant"
      checked={formData.hasAssistant}
      onChange={handleChange}/>
    לבני/ביתי סייע/ת צמוד/ה
  </label>

  {formData.hasAssistant && (
    <div className="form-group">
      <label>סוג הסייע/ת:</label>
      <select
        name="assistantType"
        value={formData.assistantType}
        onChange={handleChange}
      >
        <option value="" disabled>בחר</option>
        <option value="סייע/ת משלב/ת">כן, סייע/ת משלב/ת</option>
        <option value="סייע/ת רפואי/ת">כן, סייע/ת רופאי/ת</option>
      </select>
    </div>
  )}
</div>

        </div>
        <div className="form-section">
        <div>
          <label>
            <input type="checkbox" name="termsAccepted" checked={formData.termsAccepted} onChange={handleChange} required/>
            אני מאשר את <span onClick={handleDialogOpen} style={{ textDecoration: 'underline', cursor: 'pointer' }}>תנאי הרישום*</span>
          </label>
          <Dialog open={isDialogOpen} onClose={handleDialogClose}>
            <DialogTitle style={{ textAlign: 'center' }}>תנאי רישום</DialogTitle>
            <DialogContent>
            <DialogContentText style={{ textAlign: 'right' }}>
      <div style={{ textAlign: 'right', direction: 'rtl' }} dangerouslySetInnerHTML={{ __html: termsHtml(afternoon.field22) }} />
    </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button onClick={handleDialogClose} color="primary">
                סגור
              </Button>
            </DialogActions>
          </Dialog>
        </div>
          <div className="button-group">
        {afternoon["field13.if_pay_cc"] && (
          <button
          type="submit"
          disabled={isLoading || errors.paymentMethod === 'Field is required'}
          onClick={() => setSelectedPaymentMethod("pay_cc")}
        >
           {isLoading ? (
            'טוען...'
            ) : (
              'תשלום באשראי'
            )}
        </button>
        
        )}
        {afternoon["field13.if_pay_hok_bank"] && (
        <button
          type="submit"
          disabled={isLoading || errors.paymentMethod ==="Field is required"}
          onClick={() => setSelectedPaymentMethod("pay_hok_bank")}
        >
           {isLoading ? (
            'טוען...'
            ) : (
        'הוראת קבע בנקאית'
      )}
        </button>
      )}
      {afternoon["field13.if_pay_hok_cc"] && (
        <button
          type="submit"
          disabled={isLoading || errors.paymentMethod ==="Field is required"}
          onClick={() => setSelectedPaymentMethod("pay_hok_cc")}
        >
          {isLoading ? (
            'טוען...'
            ) : (
        'הוראת קבע באשראי'
      )}
          </button>
        )}  
         {isAdmin && (
        <button
          type="submit"
          disabled={isLoading || errors.paymentMethod ==="Field is required"}
          onClick={() => setSelectedPaymentMethod("cash_or_check")}
        >
          {isLoading ? (
            'טוען...'
            ) : (
        'תשלום במזומן/ המחאה'
      )}
        </button>
      )}
          </div>
        </div>
      </form>

<Dialog open={openSuccessDialog} onClose={() => setOpenSuccessDialog(false)}>
      <DialogContent>
        <DialogContentText>
          {dialogMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button  className="centered-button"  onClick={() => setOpenSuccessDialog(false)} color="primary">
          סגור
        </Button>
      </DialogActions>
    </Dialog>
    </div>
  );
};
export default Form;
